import React from 'react';
import { useState , useEffect} from 'react';
import { Routes, Route, Redirect} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from './i18n'
import Home from "./container/Home/Home";
import Feature from "./container/Feature/Feature";
import Policies from "./container/Policies/Policies";
import Contract from "./container/Contract/Contract";
import Reservation from './container/Reservation/Reservation';
import NewsList from "./components/Home/NewsList";
import News from "./components/Home/News";
import NewsByDate from "./components/Home/NewsByDate";
// import Login from "./container/Login/Login";
// import SignUp from "./container/Login/SignUp";
import PhnomPenhRoomDetail from './components/BranchDetail/phnom-penh-detail/room';
import ThankYou from './container/Reservation/ThankYou';
import CambodiaService from "./container/Service/Service"
import ErrorPage from './container/Units/ErrorPage';
import Feedback from './container/Units/Feedback';
import Reservation_backup from './container/Reservation/Reservation_backup'
import Gallery_PP from './container/Gallery/Gallery';
import ThankYouService from './container/Service/ThankYou';
import ThankYouContract from './container/Contract/ThankYou';

function NewRouter(props) {
    const {news} = props;
    const { i18n } = useTranslation();

      const [deviceType, setDeviceType] = useState('');
      useEffect(() => {
        const handleResize = () => {
          const width = window.innerWidth;
          if (width >= 1024) {
            setDeviceType('pc');
          } else if (width >= 768) {
            setDeviceType('tablet');
          } else {
            setDeviceType('phone');
          }
        };
        handleResize(); 
        window.addEventListener('resize', handleResize); 
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    return (
        <section>
            <Routes>
                <Route exact path='/' element={<Home news = {news} />} />
                <Route path="/service/" element={<CambodiaService />} />
                <Route path="/q&a/" element={<Policies />} />
                <Route path="/reservation/" element={<Reservation deviceType={deviceType} />} />
                <Route path="/feature/" element={<Feature/>} />
                <Route path="/feature/:featureID" element={<Feature/>} />
                <Route path = "/breakfast/" element={<CambodiaService deviceType={deviceType} />} />
                <Route path = "/rotenburo/" element={<CambodiaService deviceType={deviceType} />} />
                <Route path = "/massage/" element={<CambodiaService deviceType={deviceType} />} />
                {/* <Route path="/Service" element={<Service />} /> */}
                <Route path="/contract/" element={<Contract />} />
                <Route path = "/gallery/" element = {<Gallery_PP />} />
                <Route path="/thank-you/:selectedCity/" element={<ThankYou />} />
                <Route path = "/massage/thank-you/:selectedCity/" element={<ThankYouService />} />
                <Route path = "/contract/thank-you/:selectedCity/" element={<ThankYouContract />} />
                {/* <Route path="/ja/thank-you/:selectedCity" element={<ThankYou />} />
                <Route path="/vie/thank-you/:selectedCity" element={<ThankYou />} /> */}

                <Route path="/news/:title" element={<News news={news} />} />
                <Route path="/news" element={<NewsList news={news} />} />
                <Route path="/news-by-date/:date" element={<NewsByDate news={news} />} />
                {/* <Route path="/hotel-hn" element={<HotelHN news = {news}/>} />
                <Route path="/hotel-hcm" element={<HotelHCM news = {news} />} />
                <Route path="/hotel-dn" element={<HotelDN news = {news}/>} />
                <Route path="/hotel-hp" element={<HotelHP news = {news} />} />
                <Route path="/hai-ba-trung-detail/room" element={<HBT1RoomDetail />} />
                <Route path="/kim-ma-2-detail/room" element={<KM2RoomDetail />} />
                <Route path="/kim-ma-3-detail/room" element={<KM3RoomDetail />} />
                <Route path="/linh-lang-detail/room" element={<LLRoomDetail />} />
                <Route path="/thai-van-lung-1-detail/room" element={<TVL1RoomDetail />} />
                <Route path="/thai-van-lung-2-detail/room" element={<TVL2RoomDetail />} />
                <Route path="/le-thanh-ton-detail/room" element={<LTTRoomDetail />} />
                <Route path="/annex-detail/room" element={<AnnexRoomDetail />} />
                <Route path="/da-nang/room" element={<DNRoomDetail />} />
                <Route path="/hai-phong/room" element={<HPRoomDetail />} /> */}
                <Route path="/phnom-penh-detail/room" element={<PhnomPenhRoomDetail />} />
                <Route path='*' element={<Home news = {news} />} />
                <Route path='/feedback' element={ <Feedback /> } />                
                <Route path="/dev-test" element={<Reservation_backup />} />
            </Routes>
        </section>
    );
}

export default NewRouter;