import {Gallery} from 'react-grid-gallery'
export default function Gallery_PP() {
 const photos = [
        {
          src: "https://azumayavietnam.com/image/imagePP/gallery/0.jpg",
          width: 500,
          height: 300
        },
        {
          src: "https://azumayavietnam.com/image/imagePP/gallery/1.jpg",
          width: 500,
          height: 300
        },
        {
          src: "https://azumayavietnam.com/image/imagePP/gallery/2.jpg",
          width: 500,
          height: 300
        },
        {
          src: "https://azumayavietnam.com/image/imagePP/gallery/3.jpg",
          width: 500,
          height: 300
        },
        {
          src: "https://azumayavietnam.com/image/imagePP/gallery/4.jpg",
          width: 500,
          height: 300
        },
        {
          src: "https://azumayavietnam.com/image/imagePP/gallery/5.jpg",
          width: 500,
          height: 300
        },
        {
          src: "https://azumayavietnam.com/image/imagePP/gallery/6.jpg",
          width: 500,
          height: 300
        },
        {
          src: "https://azumayavietnam.com/image/imagePP/gallery/7.jpg",
          width: 500,
          height: 300
        },
        {
            src: "https://azumayavietnam.com/image/imagePP/gallery/8.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/9.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/10.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/11.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/12.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/13.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/14.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/15.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/16.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/17.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/18.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/19.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/20.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/21.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/22.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/23.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/24.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/25.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/26.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/27.jpg",
            width: 500,
            height: 300
          },
          {
            src: "https://azumayavietnam.com/image/imagePP/gallery/28.jpg",
            width: 500,
            height: 300
          }
      ];
      return(
        <Gallery images={photos} />
      )
}